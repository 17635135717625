.radio-button {
    min-width: 36px;
    min-height: 36px;
    border-radius: 18px;
    border-style: solid;
    background: none;
    border-color: #1F449C;
    vertical-align: middle;
}
.radio-button-selected {
    min-width: 36px;
    min-height: 36px;
    border-radius: 18px;
    border-style: solid;
    background: #1F449C;
    border-color: #1F449C;
    color: white;
    vertical-align: middle;
}
.radio-button-label {
    font-size: 22pt;
    font-weight: 600;
    margin: 0;
    margin-left: 16px;
    vertical-align: middle;
}
.radio-button-with-label {
    margin-bottom: 16px;
}