.quiz-button {
    border-style: none none solid none;
    border-color: black;
    padding-bottom: 0;
    background: none;
    font-size: 24pt;
    cursor: pointer;
    transition: 0.3s;
}
.quiz-button:hover {
    border-color: #1F449C;
    color: #1F449C;
}

.quiz-answer-correct {
    font-weight: 600;
    color: green;
}
.correct-answer {
    color: green;
    font-weight: 600;
    text-decoration: underline;
}

/* Quiz Results */
.quiz-results {
    width: 60%;
    max-width: 90%;
    text-align: center;
    margin: auto;
    margin-top: 240px;
}
.quiz-results h3 {
    margin: 0px 0 16px 0;
    font-size: 24pt;
}