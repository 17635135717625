.bottom-right-button {
  display: flex;
  position: absolute;
  bottom: 0 !important;
  right: 0 !important;
  margin-right: 7% !important;
  margin-bottom: 1% !important;
}

.bottom-right-button span {
  position: relative;
  bottom: 18%;
  display: inline-block;
}

.bottom-left-button {
  display: flex;
  position: absolute;
  bottom: 0 !important;
  left: 0 !important;
  margin-left: 7% !important;
  margin-bottom: 1% !important;
}

.bottom-left-button span {
  position: relative;
  bottom: 18%;
  display: inline-block;
}

.next-button {
  width: 230px;
  height: 65px;
  background-color: white;
  color: #1f449c;
  border-radius: 16px;
  border-color: #1f449c;
  border-width: 3px;
  font-size: 20pt;
  transition: 0.3s;
  font-weight: bold;
  text-shadow: 1px 0 #888888;
  letter-spacing: 1px;
  margin-top: 6px;
  margin-right: 12px;
}

.next-button:hover {
  background-color: #1f449c;
  color: white;
}

.next-button-disabled {
  width: 230px;
  height: 65px;
  background-color: white;
  color: #5f6368;
  border-radius: 12px;
  border-color: #5f6368;
  font-size: 20pt;
  transition: 0.3s;
  font-weight: bold;
  text-shadow: 1px 0 #888888;
  margin-top: 6px;
  margin-right: 12px;
  cursor: not-allowed;
}

/* Retake Quiz */
.retake-quiz-button {
  width: 220px;
  height: 45px;
  background-color: white;
  color: #1f449c;
  border-radius: 12px;
  border-color: #1f449c;
  font-size: 16pt;
  transition: 0.3s;
  font-weight: 600;
  margin-top: 6px;
  margin-right: 12px;
}
.retake-quiz-button:hover {
  background-color: #1f449c;
  color: white;
}

/* Help Button */
.help-button {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 20%;
  margin-top: 6.5%;
  background: none;
  border-style: none;
  text-align: center;
  transition: 0.3s;
  cursor: pointer;
}

.help-button img {
  width: 40px;
  height: 40px;
}
.help-button p {
  font-size: 16pt;
  font-weight: 700;
  margin: 4px 0 0 0;
  text-align: center;
}

.close-button {
  width: 180px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #1f449c;
  color: white;
  border-radius: 10px;
  border-color: #1f449c;
  font-size: 16pt;
  transition: 0.3s;
  font-weight: 600;
  margin-top: 110px;
}

.close-button:hover {
  background-color: #1f449c;
  color: white;
}

.button-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  margin-top: 6%;
  margin-right: 8%;
}

.top-left-button {
  display: flex;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  margin-left: 7% !important;
  margin-top: 0% !important;
}

.top-left-button span {
  position: relative;
  bottom: 18%;
  display: inline-block;
}

.top-right-button {
  display: flex;
  position: absolute;
  top: 0 !important;
  right: 0 !important;
  margin-right: 7%;
  margin-top: 0%;
}

.top-right-button span {
  position: relative;
  bottom: 18%;
  display: inline-block;
}

.spanish-button {
  width: 180px;
  height: 55px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #1f449c;
  color: white;
  border-radius: 10px;
  border-color: #1f449c;
  font-size: 16pt;
  transition: 0.3s;
  font-weight: 600;
  margin-top: 110px;
}

.spanish-button span {
  position: relative;
  bottom: 14%;
  display: inline-block;
}

.spanish-button-custom {
    width: 180px;
    height: 55px;
    background-color: #1f449c;
    color: white;
    border-radius: 10px;
    border-color: #1f449c;
    font-size: 16pt;
    transition: 0.3s;
    font-weight: 600;
}

.spanish-button-custom span {
  position: relative;
  bottom: 14%;
  display: inline-block;
}

.spanish-button:hover {
  background-color: white;
  color: #1f449c;
}
